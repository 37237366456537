import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import rb1 from "../../Assets/Projects/rb1.png";
import aurora from "../../Assets/Projects/aurora.png";
import pch1 from "../../Assets/Projects/pch1.png";
import saic1 from "../../Assets/Projects/saic1.png";
import tx1 from "../../Assets/Projects/tx1.png";
import ps1 from "../../Assets/Projects/ps1.png";



function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
         <strong className="purple">Project </strong> Work
        </h1>
        <p style={{ color: "white" }}>
          From gaming, to history, to the inception of an entire Service Desk - There is no limit to what I work on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rb1}
              title="Knowledge Base Overhaul"
              company= "Red Bull"
              description="I was tasked to overhaul existing Knowledge Base Articles. I delivered templates, introduced process flow charts, a document review cycle, and created a project plan where I coordinated meetings with subject matter experts in order to create new and improved documentation."
              demoLink="/projects/redbull"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps1}
              isBlog={false}
              title="Account Management Tool.ps1"
              company="Personal Project"
              description="In my spare time, I trained my PowerShell scripting skills to create a GUI Account Management Tool. This PowerShell tool allows an administrator to create and modify accounts using Active Directory."
              demoLink="/projects/adpowershell"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tx1}
              isBlog={false}
              title="ITSM Platform Implementation"
              company="Tennessee Tech University"
              description="I took charge of implementing the University's new IT Service Management Platform, TeamDynamix. I created ticket categorization, workflows, ticket forms, and created the processes for handling tickets. Additionally, I set meetings with subject matter experts, to ensure that their processes were represented in the system."
              demoLink="/projects/tntech"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aurora}
              isBlog={false}
              title="Overwatch eSports Team"
              company="Team Aurora"
              description="Acting as the Team Manager for Team Aurora, I created all logos and branding, sought out sponsorships, developed recruitment and training methodologies, as well as set tournament and skirmishes with other teams. Additionally, I created a database to track all applicants, games played, and performance."
              demoLink="/projects/aurora"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={saic1}
              isBlog={false}
              title="Service Desk Implementation"
              company="SAIC"
              description="Designed and implemented the foundation work for the SAIC-VITA service desk. I created process flows, defined incident creation, constructed policies and procedures, and communicated with stakeholders to get the service desk operational."
              demoLink="/projects/saic"
            />
          </Col>
 
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pch1}
              isBlog={false}
              title="PC Haven"
              company="Personal Project"
              description="I provided custom computing solutions for several clients as a small side-job. Solutions varied from home networks, to custom built PCs, to hardware and software support. I ran a website, created my own branding, and created a database to track all records."
              demoLink="/projects/pchaven"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
