import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              My  <span className="purple"> professional experience</span> at a glance
            </h1>
            <h4 style={{ fontSize: "1.2em" }}> Scroll down to travel back and observe my professional journey.
            <br />
            All project deliverables from my work experience can be found under my projects page.</h4>
                                          
          </Col>

        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
