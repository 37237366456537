import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ps1 from "../../Assets/Projects/ps1.png";
import ps2 from "../../Assets/Projects/ps2.png";
import ps3 from "../../Assets/Projects/ps3.png";
import ps4 from "../../Assets/Projects/ps4.png";
import ps5 from "../../Assets/Projects/ps5.png";
import ps6 from "../../Assets/Projects/ps6.png";
import ps7 from "../../Assets/Projects/ps7.png";



function ADPowershell() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         Account 
         <strong className="purple"> Management </strong>Tool.ps1
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
        With no skills in Powershell, I decided to develop a GUI script in Powershell that would encompass a numerous amount of account administration tools.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={10} className="project-card">
            <h4> Skills Used</h4>
            < br/>
            <p style={{ color: "white", textAlign: 'left' }}>
            I had zero skills going into this, only an idea to improve account management.
            <ul>
              <li>Researched Powershell scripting.</li>
              <li>Identified common account administration tasks perform in <b>Active Directory</b></li>
              <li>Developed an account administration GUI tool with AD functionalities.</li>
            </ul>
            </p>
            < br/>
            <h4> Deliverables</h4>
            < br/>
          </Col>


          <h4> Gallery</h4>

          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <script src="https://gist.github.com/mauradin/3cc6d905c50ef13a1b4e8aad5852ba72.js"></script>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps1}
              description="Primary GUI home."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps2}
              description="Hybrid AD Account tool; utilizing New-RemoteMailbox to create accounts."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps3}
              description="Unlock AD Account tool."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps4}
              description="Group Membership Manager tool."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps5}
              description="Enable/Disable Account tool."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps6}
              description="Rename Account tool."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps7}
              description="AD OU Mover tool."

            />
          </Col>

          </Row>
        </Row>

        <Particle />
      </Container>
    </Container>



    
  );
  
}

export default ADPowershell;
