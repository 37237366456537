import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiMicrosoftexchange,
  SiMicrosoftazure,
  SiFortinet,
  SiAdobe,
  SiVmware,
  SiMicrosoftoffice,
  SiMicrosoftoutlook,
  SiMicrosoftsharepoint,
  SiDatto,
  SiVisualstudiocode,
  SiPowershell,
  SiMicrosoftteams,
} from "react-icons/si";
import { 
  VscRemoteExplorer 
} from "react-icons/vsc";


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftazure />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdobe />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftoffice />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftoutlook />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftexchange />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftsharepoint />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftteams />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <VscRemoteExplorer />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPowershell/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVmware />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDatto />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFortinet />
      </Col>
    </Row>
  );
}

export default Techstack;
