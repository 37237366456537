import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Iframe from 'react-iframe';



function PoliciesProcedures() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         Policies 
         <strong className="purple"> & </strong> Procedures
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
        Examples of flowcharts I have made in the past.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>

          <h4>Deliverable Examples</h4>

          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1VjxSYTxl0KGiev9DpIV31pUolCgt_gZC/preview"  width="100%" height="650"/>
          </Col>
          
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1wzKHGnc9TLJaiWON6NziitdDq831Ny3u/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1oaEJnxa4ICJeRNOC5SB4X6E1ysRaOZgX/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1UI7RS7bV45mBIoOlFi7plL7Y8rvazRhD/preview"  width="100%" height="650"/>
          </Col>

          </Row>
        </Row>
        <Particle />
      </Container>
    </Container>
  );
  
}

export default PoliciesProcedures;
