import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Iframe from 'react-iframe';



function JobAids() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         Job 
         <strong className="purple"> Aids </strong> 
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
        Examples of job aids I have made in the past.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>

          <h4>Deliverable Examples</h4>


          <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1Hr0PV7hyJLS3FgBizd711OiX0LSzAOE3/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1O76tte_08JyVtAmBrKYnqKbXDVVWiLe7/preview"  width="100%" height="650"/>
          </Col>
          </Row>

          <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1xj-kcud659ootEgae9JooEfUPg3zemyD/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1ceuRo19KmLwxpEI7gBHrGh4iM-vfARzG/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1C9Q4y7mSLx6McKgfMoLrCKjKnps7U-xZ/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1SXuztUYKKtQyaJXMA0bh33Kp8h2wsB16/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        </Row>
        <Particle />
      </Container>
    </Container>
  );
  
}
export default JobAids;
