import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Iframe from 'react-iframe';


function TnTech() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         Tennessee 
         <strong className="purple"> Tech University </strong> | ITSM Platform Implementation
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
          Working at the University, I was responsible for implementing TeamDynamix, an IT Service Management platform. With this project, I was able to create communication strategies, project plans, create deliverables, and also create technical deliverables such as ticket categorization, workflows, processes, and procedures.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={10} className="project-card">
            <h4> Skills Used</h4>
            < br/>
            <p style={{ color: "white", textAlign: 'left' }}>
            <ul>
              <li>I acted as the <b>project manager</b>.</li>
              <li>I created all <b>ticket categorization and workflows</b>.</li>
              <li>Built <b> project plans and communication strategies</b>.</li>
              <li>Created many technical deliverables.</li>
            </ul>
            </p>
            <h4>Deliverables</h4>
          < br/>
          <h4>Ticketing Categorization and Form Matrix</h4>
          <Iframe url="https://drive.google.com/file/d/1pjVZj2Fj6VBPvtoWXyiasXOo4sSh9RsS/preview" width="100%" height="650"/>
          
          <h4> Communication Strategy</h4>
          <Iframe url="https://drive.google.com/file/d/1VjxSYTxl0KGiev9DpIV31pUolCgt_gZC/preview"  width="100%" height="650"/>
          < br/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1N8TQOztPrPtF3py9umLYAnlKHbl8gPNQ/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1MIKRl6ZrJrITlKRX8Hv0CJL-UGjMRMRZ/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1xj-kcud659ootEgae9JooEfUPg3zemyD/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1mp8Y1ncKWDdWOOGVpN0f0SK4_y8b6i7r/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1SlDeSvB1RYJih08iwuaSWf_iLotU_7PA/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Particle />
      </Container>
    </Container>

  );
  
}

export default TnTech;
