import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Iframe from 'react-iframe';



function Troubleshooting() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         Troubleshooting 
         <strong className="purple"> Guides </strong> 
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
        Examples of flowcharts I have made in the past.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>

          <h4>Deliverable Examples</h4>

          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1wc4fljZOG89j2isIuIJqtCo7If_gyeiK/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1ceuRo19KmLwxpEI7gBHrGh4iM-vfARzG/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1kn9_mlNoLM3Y3X8QgYI1ui--a_Alzt49/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1O76tte_08JyVtAmBrKYnqKbXDVVWiLe7/preview"  width="100%" height="650"/>
          </Col>

          </Row>
        </Row>
        <Particle />
      </Container>
    </Container>
  );
  
}

export default Troubleshooting;
