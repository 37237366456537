import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            My name is <span className="purple">Jesse Campanella </span>
            .
            <br />I am a gamer by nature, so I am used to having many names, different roles, multi-tasking, and problem-solving to the highest degree.
            <br />
            <br />I have been working in the IT industry since my highschool graduation in 2014. It is there where I have earned many titles in the industry - IT Support, Project Coordinator, Service Desk Analyst, Service Desk Supervisor, and Knowledge Base Administrator, just to name a few.
            <br />
            <br />
            Apart from the IT field, you will find me passionately enjoying:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Gaming - World of Warcraft, League of Legends, Starcraft, Warzone, and whatever is the flavor of the month.
            </li>
            <br />
            <li className="about-activity">
              <ImPointRight /> Implementing technology - Built and supported my friends computers, outfitted numerous Twitch streams.
            </li>
            <br />
            <li className="about-activity">
              <ImPointRight /> Hiking - Virgin Falls {'(2019, 2020, 2021)'}, Cummins Falls, Mammoth Caves, Fall Creek Falls.
            </li>
            <br />
            <li className="about-activity">
              <ImPointRight /> Cooking - I'm no pro by any means, however, I do enjoy a selection of peppers and spices to invigorate my foods.
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "SCV ready."{" "}
          </p>
          <footer className="blockquote-footer">Terran SCV, Starcraft II</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
