import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Iframe from 'react-iframe';


function SAIC() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading"> 
         <strong className="purple"> SAIC </strong> | Service Desk Implementation
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
        My time with SAIC was spent building and mapping out policies and procedures that were influential in the initial construction of the Service Desk for the Virginia Information Technolgy Agency (VITA) as per the newly signed contract. SAIC was introducing Service Now (Later re-branded as KeyStone Edge) as the new ITSM platform for VITA.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={10} className="project-card">
            <h4> Skills Used</h4>
            < br/>
            <p style={{ color: "white", textAlign: 'left' }}>
            I created flow charts, task boards, job aids, developed policies and procedures using ITIL best practices, training aids, and wrote an entire Transition write-up that compiled procedural changes, definitions, etc. I also played a role in the translation of the Knowledge Base from the old Service Desk to the new one by creating small task forces to convert over 2000 KB documents.
            I also created several documents that visualized common process flows and checked to see if the new system could adapt to the current process flow, or if it would hit a hard break and require change.
            Additionally, I restructured the entire file organization system by redesigning the structural layout to reduce navigation to an efficient, two-click maximum, and fast loading design.           
            </p>

            < br/>
            <h4> Deliverables</h4>
            < br/>
            <p style={{ color: "white", textAlign: 'left' }}>
            <ul>
              <li><strong>KeyStone Edge Transition:</strong> A master document that contained embedded technical writing documents and outlined key definitions while highlighting changes</li>
              <li><strong>Incident Automation Draft:</strong> A draft of how automation would take place regarding ticket assignments and priorities based on certain criterion.</li>
              <li><strong>Outage Process Flow:</strong> A short and sweet document that detailed the process on handling user-reported outages.</li>
              <li><strong>Process Flows:</strong> Several, much needed, process flows were outlined and visualized in the documentation I created.</li>
              <li><strong>Incident Creation Document:</strong> This document served as the current process for logging incidents into ServiceNow/KeyStone Edge.</li>
            </ul>
            </p>
  
          </Col>
          </Row>

          <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1zK9ni5XdrVX1BK9QFOqs7sndcm1R0oOg/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1WKZLV23BN5T6m23sGN5mdbgdTRjWS1K8/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1v1L3-W6XqHIyKNYH3M94DoraS7K91E3e/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1xpFUG0vI1_y9OdRcUaad2InNNqmKua6u/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Particle />
      </Container>
    </Container>



    
  );
  
}


export default SAIC;
