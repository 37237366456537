import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Iframe from 'react-iframe';


function Redbull() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         Red 
         <strong className="purple"> Bull </strong> | Knowledge Base Overhaul
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
        Red Bull reached out to me with a proposal to overhaul their service desk knowledge base over the course of a 3 month contract.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={10} className="project-card">
            <h4> Skills Used</h4>
            < br/>
            <p style={{ color: "white", textAlign: 'left' }}>
            <ul>
              <li>I acted as the <b>Knowledge Base Administrator</b>.</li>
              <li>Redesigned existing knowledge base articles in ServiceNow.</li>
              <li>Used Microsoft Planner to create a project plan for new and existing articles.</li>
              <li>Created a knowledge base lifecycle.</li>
            </ul>
            </p>

          
          </Col>

          <h4>Deliverable Examples</h4>

          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1wc4fljZOG89j2isIuIJqtCo7If_gyeiK/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1ceuRo19KmLwxpEI7gBHrGh4iM-vfARzG/preview"  width="100%" height="650"/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1kn9_mlNoLM3Y3X8QgYI1ui--a_Alzt49/preview"  width="100%" height="650"/>
          </Col>

          </Row>
        </Row>
        <Particle />
      </Container>
    </Container>
  );
  
}


export default Redbull;
