import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Iframe from 'react-iframe';
import aur1 from "../../Assets/Projects/aur1.png";
import aur2 from "../../Assets/Projects/aur2.png";
import aur3 from "../../Assets/Projects/aur3.png";
import aur4 from "../../Assets/Projects/aur4.png";
import aur5 from "../../Assets/Projects/aur5.png";



function Aurora() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         Team 
         <strong className="purple"> Aurora </strong> | eSports Team
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
        Team Aurora is a deprecated project I embarked on with one of my good friends Austin Williams. It was a dream for us to take an alternate route towards a successful career. What it ended up being was a great time with an opportunity for dozens of skills to be learned.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={10} className="project-card">
            <h4> Skills Used</h4>
            < br/>
            <p style={{ color: "white", textAlign: 'left' }}>
            I had zero skills going into this, only ideas. I used my practical mentality to quickly draft models for our success.
            <ul>
              <li>I acted as the <b>team manager</b>.</li>
              <li>I created all <b>logos and branding</b>.</li>
              <li>A <b>website from scratch</b> that included contact forms, application forms, the team roster, and a route for affiliates to reach out to us.</li>
              <li>A <b>social media page</b> that announced our winnings and kept our followers up to date on our latest news.</li>
              <li>I <b>scheduled tournaments</b> and events with our adversaries.</li>
              <li>A <b>database</b> that tracked all of our game matches, our roster, our adversaries, our win ratios, and general performance.</li>
              <li>A <b>database </b>that tracked all applicants and affiliates.</li>
              <li>I utilized an <b>online collaboration platform</b> to hold meetings to review our recorded footage to improve game play.</li>
              <li>I created an affiliate presentation to inspire investors.</li>
            </ul>
              Through my dedication and persistence the team was actually able to acquire three total sponsors that aided in the financial support for the team.
            </p>
            < br/>
            <h4> Deliverables</h4>
            < br/>
            
            <h4> Tracking Book</h4>
          <Iframe url="https://airtable.com/embed/shrvP4ploxJb5pYLa?backgroundColor=red" frameborder="0" onmousewheel="" width="100%" height="650"/>
          
          <h4> Applicant/Roster Book</h4>
          <Iframe url="https://airtable.com/embed/shre7Z4BQ5ICzJW5B?backgroundColor=green"  width="100%" height="650"/>
          < br/>
          </Col>

          <h4> Gallery</h4>

          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aur1}
              description="Screencap of the site's roster."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aur2}
              description="I set up complete Twitch channels for every member."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aur3}
              description="Screenshot of the application page."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aur4}
              description="Screenshot of our affiliation page."

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aur5}
              description="Screenshot of our about section."

            />
          </Col>

          </Row>
        </Row>
        <Particle />
      </Container>
    </Container>

  );
  
}


export default Aurora;

