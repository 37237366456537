import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Redbull from "./components/Projects/Redbull";
import ADPowershell from "./components/Projects/ADPowershell";
import TnTech from "./components/Projects/TnTech";
import Aurora from "./components/Projects/Aurora";
import SAIC from "./components/Projects/SAIC";
import PCHaven from "./components/Projects/PCHaven";
import Footer from "./components/Footer";
import Resume from "./components/Resume/Resume";
import Writings from "./components/TechWriting/Writings"
import Flowcharts from "./components/TechWriting/Flowcharts"
import PoliciesProcedures from "./components/TechWriting/PoliciesProcedures"
import Troubleshooting from "./components/TechWriting/Troubleshooting"
import JobAids from "./components/TechWriting/JobAids"
import {
  BrowserRouter as Router,
  Route,
  Routes,
 // Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/writings" element={<Writings />} />
          <Route path="/writings/flowcharts" element={<Flowcharts />} />
          <Route path="/writings/troubleshooting" element={<Troubleshooting />} />
          <Route path="/writings/jobaids" element={<JobAids />} />
          <Route path="/writings/policiesprocedures" element={<PoliciesProcedures />} />
          <Route path="/projects/redbull" element={<Redbull />} />
          <Route path="/projects/adpowershell" element={<ADPowershell />} />
          <Route path="/projects/tntech" element={<TnTech />} />
          <Route path="/projects/aurora" element={<Aurora />} />
          <Route path="/projects/saic" element={<SAIC />} />
          <Route path="/projects/pchaven" element={<PCHaven />} />
          <Route path="/projects/teamaurora" element={<Aurora />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
