import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myHeadshot from "../../Assets/me.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Timeline from "./Timeline";
//import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={8} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
              I am
              </h1>
              <h1 className="heading-name">
                
              <strong className="main-name">Jesse Campanella</strong>
              </h1>
              <p className="home-about-body">
              
              Lifelong gamer, avid hiker, reliable team player, and consistent contributor.</p>

              <p className="home-about-body">I am a versatile and structured IT Engineer with 10 years of experience in the IT industry. I am passionate about process improvement and documentation, always striving to stay ahead with the latest advancements in technology.
              </p>
            
              <p className="home-about-body">With a strong foundation in IT project management and technical support, I excel in coordinating and managing complex technical projects, implementing Agile methodologies, and improving documentation and processes. I am PMI-CAPM certified and proficient in Microsoft 365, Azure, and other key technologies.</p>
                
                This website is written in React/.js, and is hosted on an Azure Static Web App.
               
            </Col>

            <Col md={4} style={{ paddingBottom: 20 }}>
              <img
                src={myHeadshot}
                alt="headshot"
                className="img-fluid"
                style={{ maxHeight: "400px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
      <Timeline />
    </section>
  );
}


export default Home;
/*
Archive: if you're web inspecting, hi. Just learning. :)
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>

                add <Type /> 29.
                add 28/30 <div style={{ padding: 50, textAlign: "left" }}>
                </div>
*/