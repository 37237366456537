import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import Particle from "../Particle";
import ja1 from "../../Assets/Writings/jobaids.png";
import rb1 from "../../Assets/Writings/rb1.png";
import pol1 from "../../Assets/Writings/policies.png";
import trouble from "../../Assets/Writings/trouble.png";


function Writings() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
         <strong className="purple">Technical </strong> Writings
        </h1>
        <p style={{ color: "white" }}>
          Job aids, policies, procedures, work flows, and various other technical documentation.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rb1}
              title="Flowcharts"
              demoLink="/writings/flowcharts"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={trouble}
              isBlog={false}
              title="Troubleshooting Guides"
              demoLink="/writings/troubleshooting"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pol1}
              isBlog={false}
              title="Policies & Procedures"
              demoLink="/writings/policiesprocedures"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ja1}
              isBlog={false}
              title="Job Aids"
              demoLink="/writings/jobaids"              
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Writings;
