import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Iframe from 'react-iframe';



function Flowcharts() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         Flow 
         <strong className="purple"> Charts </strong> 
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
        Examples of flowcharts I have made in the past.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>

          <h4>Deliverable Examples</h4>

          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1pjVZj2Fj6VBPvtoWXyiasXOo4sSh9RsS/preview"  width="100%" height="650"/>
          </Col>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1-ZAKogXIKHTk4K8Y6Ru4RygW8mRb7bFf/preview"   width="100%" height="650"/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={10}>
            <Iframe url="https://docs.google.com/spreadsheets/d/1x8qmbR6tOnQz91Z2v5Np18aEezx5lp9-/edit?usp=sharing&ouid=113217782254104806390&rtpof=true&sd=true"  width="100%" height="650"/>
          </Col>
          </Row>

          <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/11AbPPcRYQluo6VxF8u0lnXuw3R4Bqiqk/preview"  width="100%" height="650"/>
          </Col>
         
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1Lj6K7HB6L4wFVQ6IoZLABxHmxPW2MwHG/preview"   width="100%" height="650"/>
          </Col>
        </Row>
        
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/16UxF2W_0FuLmlYmdZYNrNy3dp4ZRRDet/preview"  width="100%" height="650"/>
          </Col>

          <Col md={5}>
            <Iframe url="https://drive.google.com/file/d/1x4VjvZSzP7GFBe1thrL6gJFWfgyKIHdv/preview"  width="100%" height="650"/>
          </Col>

          </Row>
        </Row>
        <Particle />
      </Container>
    </Container>
  );
  
}

export default Flowcharts;
