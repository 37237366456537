import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import pch2 from "../../Assets/Projects/pch2.png";


function PCHaven() {
  return (
    <Container fluid className="project-section">
      
      <Container>
        <h1 className="project-heading">
         PC  
         <strong className="purple"> Haven </strong> | Custom Computing Solutions
        </h1>
        <p style={{ color: "white", textAlign: 'center' }}>
          It all began with a simple idea: How can I provide affordable computer solutions to those in need of the latest and greatest in technology?
        < br/>
        < br/>
          PC Haven was an Orlando-based home business. The goal was to bring technical support to local residents, while ensuring a valued customer experience. We specialized in the set up of small home businesses, as well as small offices. We were skill in curating custom computer and server systems for based on need.        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", color: 'white' }}>
          <Col md={10} className="project-card">
            <h4> Skills Used</h4>
            < br/>
            <p style={{ color: "white", textAlign: 'left' }}>
            I had learned a few things from building gaming computers for my friends. I've always wanted to expand on that passion and steer my path towards entire computing solutions for small businesses and individuals.
            <ul>
              <li>I created all <b>logos and branding</b></li>
              <li>A <b>website from scratch</b> that included contact forms, order forms, and product pages.</li>
              <li>A <b>social media page</b> that showcased builds and news.</li>
              <li>A <b>database</b> that tracked all purchases, sales, and invoices.</li>
            </ul>
            </p>
          </Col>

          <h4> Gallery</h4>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={pch2}
            description="Weekly ads were ran on our social media pages to organically reach new customers."

          />
        </Col>
        </Row>

        </Row>
        <Particle />
      </Container>
    </Container>



    
  );
  
}


export default PCHaven;
